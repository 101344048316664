<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 점검유형 -->
          <c-select
            type="none"
            codeGroupCd="PSR_CHECK_TYPE_CD"
            default="first"
            itemText="codeName"
            itemValue="code"
            name="psrCheckTypeCd"
            label="점검유형"
            v-model="searchParam.psrCheckTypeCd"
            @setCodeData="getList"
            @datachange="getList"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="대분류"
          tableId="riskHazrd01"
          :columns="grid1.columns"
          :data="grid1.data"
          @rowClick="rowClick1"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="firstEditable" label="등록" icon="add_circle" @btnClicked="() => { type = '1', openPop() }" />
              <!-- <c-btn label="검색" icon="search" @btnClicked="getList" /> -->
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template>
              <q-chip outline square
                color="amber"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="() => { type = '1', linkClick(props.row, col, props.pageIndex) }">
                수정
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="중분류"
          tableId="riskHazrd01"
          :columns="grid2.columns"
          :data="grid2.data"
          @rowClick="rowClick2"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="secondEditable" label="등록" icon="add_circle" @btnClicked="() => { type = '2', openPop() }" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template>
              <q-chip outline square
                color="amber"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="() => { type = '2', linkClick(props.row, col, props.pageIndex) }">
                수정
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="소분류"
          tableId="riskHazrd01"
          :columns="grid3.columns"
          :data="grid3.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="thirdEditable" label="등록" icon="add_circle" @btnClicked="() => { type = '3', openPop() }" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template>
              <q-chip outline square
                color="amber"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="() => { type = '3', linkClick(props.row, col, props.pageIndex) }">
                수정
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'pre-startup-review-item',
  data() {
    return {
      searchParam: {
        plantCd: null,
        psrCheckTypeCd: null,
        psrDataLevelCd: 'PDL0000001',
      },
      grid1: {
        columns: [
          {
            name: 'sopPrestartupCheckItemName',
            field: 'sopPrestartupCheckItemName',
            label: '항목명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            style: 'width:70px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'sopPrestartupCheckItemName',
            field: 'sopPrestartupCheckItemName',
            label: '항목명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            style: 'width:70px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      grid3: {
        columns: [
          {
            name: 'sopPrestartupCheckItemName',
            field: 'sopPrestartupCheckItemName',
            label: '항목명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            style: 'width:70px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      popupData: {
        first: '',
        second: '',
        psrCheckTypeCd: '',
      },
      type: '',
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    firstEditable() {
      return this.editable;
    },
    secondEditable() {
      return this.editable && this.popupData.first;
    },
    thirdEditable() {
      return this.editable && this.popupData.first && this.popupData.second;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.psr.checkItem.list.url
      // code setting
      // list setting
      // 점검유형 데이터 받은 후 조회 처리
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
        this.grid2.data = []
        this.grid3.data = []
        this.popupData.psrCheckTypeCd = this.$_.clone(this.searchParam.psrCheckTypeCd);
        this.popupData.first = null;
        this.popupData.second = null
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (!row) return;
      this.openPop(row);
    },
    openPop(data) {
      this.popupOptions.title = '가동전점검항목 상세'; // 작업허가서 상세
      this.popupOptions.param = {
        sopPrestartupCheckItemId: data ? data.sopPrestartupCheckItemId : '',
        psrDataLevelCd: data ? data.psrDataLevelCd : '',
        level: {
          psrCheckTypeCd: this.popupData.psrCheckTypeCd,
          first: this.type === '2' || this.type === '3' ? this.popupData.first : '',
          second: this.type === '3' ? this.popupData.second : '',
        }
      };
      this.popupOptions.target = () => import(`${'./preStartupReviewItemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (this.type === '1') {
        this.getList();
      } else if (this.type === '2') {
        this.rowClick1({ sopPrestartupCheckItemId: this.popupData.first })
      } else {
        this.rowClick2({ sopPrestartupCheckItemId: this.popupData.second })
      }
    },
    rowClick1(row) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        psopPrestartupCheckItemId: row.sopPrestartupCheckItemId
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
        this.grid3.data = []
        this.popupData.first = row.sopPrestartupCheckItemId;
        this.popupData.second = null
      },);
    },
    rowClick2(row) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        psopPrestartupCheckItemId: row.sopPrestartupCheckItemId
      }
      this.$http.request((_result) => {
        this.grid3.data = _result.data;
        this.popupData.second = row.sopPrestartupCheckItemId;
      },);
    }
  }
};
</script>
